import { backendCreateCheckOutUrl } from "../urls";

export const createCheckOut = checkOut => {
    return fetch(backendCreateCheckOutUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(checkOut)
    });
};
