import { Button, Row, Col } from "react-bootstrap";
import logo from "../../resources/logo_boundry.svg"
import cartIcon from '../../resources/icons/cart-icon.svg';
import "./style.css";

const Product = ({ product, onAddToCart, onClick, className }) => {
    return <Row onClick={onClick} className={`ks-product ${className ?? ""}`}>
        <Col xs={12} md={6} className="h-100">
            <img src={product.thumbnail} alt={product.name} className="ks-thumbnail" />
        </Col>
        <Col xs={12} md={6} className="ks-product-info h-100">
            <img src={logo} alt={product.name} className="ks-product-logo" />
            <div className="ks-info-container">
                <h4>{product.name}</h4>
                <p>{`Cena: ${(product.price / 100).toFixed(2)} zł`}</p>
            </div>
            <Button
                variant="dark"
                size="sm"
                className="ks-product-add-to-cart d-flex mx-auto"
                onClick={e => {
                    e.stopPropagation();
                    onAddToCart(product);
                }}
            >
                <p>Dodaj do koszyka</p>
                <img src={cartIcon} alt="Cart Icon" className="ks-product-add-to-cart-icon" />
            </Button>
        </Col>
    </Row>
};

export default Product;