import { backendEmailConfirmOrderUrl, backendEmailContactFormUrl, backendEmailNotifyOrderUrl } from "../urls"

export const sendForm = ({ name, email, content }) => {
    return fetch(backendEmailContactFormUrl, {
        method: "POST",
        body: JSON.stringify({
            name,
            email,
            content
        })
    })
}

export const confirmOrder = (id) => {
    return fetch(`${backendEmailConfirmOrderUrl}/${id}`);
}

export const notifyOrder = (id) => {
    return fetch(`${backendEmailNotifyOrderUrl}/${id}`);
}