import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.css';
import logo from '../../resources/logo-white.svg';
import jarIcon from '../../resources/icons/jar-icon.svg';
import { aboutUsUrl, cartUrl, contactUrl, homeUrl, menuUrl } from '../../urls';
import CartIcon from '../CartIcon/CartIcon';

const NavBar = ({ itemsCount }) => {
    return <>
        <Navbar collapseOnSelect expand="lg" variant="dark" className="ks-navbar d-lg-none">
            <Navbar.Brand className="mx-auto mb-3">
                <Link to={homeUrl}>
                    <img
                        alt=""
                        src={logo}
                        className="ks-brand d-inline-block align-top"
                    />
                </Link>
            </Navbar.Brand>
            <Navbar.Collapse className="responsive-navbar-nav">
                <Nav className="w-100 justify-content-center">
                    <NavLink eventKey="1" to={menuUrl}>Menu</NavLink>
                </Nav>
                <Nav className="w-100 justify-content-center">
                    <NavLink eventKey="2" to={aboutUsUrl}>O nas</NavLink>
                </Nav>
                <Nav className="w-100 justify-content-center">
                    <NavLink eventKey="3" to={contactUrl}>Kontakt</NavLink>
                </Nav>
            </Navbar.Collapse>
            <div className="ks-nav-link ks-cart-icon-container mx-auto mb-3">
                <CartIcon to={cartUrl} itemsCount={itemsCount} className="nav-link" />
            </div>
            <Navbar.Toggle aria-controls=".responsive-navbar-nav" className="w-100" />
        </Navbar>

        <Navbar collapseOnSelect expand="lg" variant="dark" className="ks-navbar d-none d-lg-block ">
            <Nav className="w-100 justify-content-center">
                <NavLink to={menuUrl}>Menu</NavLink>
                <NavLink to={aboutUsUrl}>O nas</NavLink>
                <Navbar.Brand className="mx-auto">
                    <Link to={homeUrl}>
                        <img
                            alt=""
                            src={logo}
                            className="ks-brand d-inline-block align-top"
                        />
                    </Link>
                </Navbar.Brand>
                <NavLink to={contactUrl}>Kontakt</NavLink>
                <div className="ks-nav-link ks-cart-icon-container mx-auto">
                    <CartIcon to={cartUrl} itemsCount={itemsCount} className="nav-link" />
                </div>
            </Nav>
        </Navbar>
    </>
};

const NavLink = ({ children, ...props }) => {
    return <div className="ks-nav-link mx-auto">
        <Nav.Link as={Link} {...props}>
            <div>
                <img src={jarIcon} alt="Jar Icon" className="ks-nav-link-icon" />
                <span className="ks-nav-link-text">{children}</span>
            </div>
        </Nav.Link>
    </div>
}

export default NavBar;