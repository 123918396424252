import { Link, Outlet } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { adminDiscountsUrl, adminOrdersUrl, adminProductsUrl, adminUrl } from "../urls";

const Admin = () => {
    return <>
        <Navbar collapseOnSelect expand="lg" variant="dark" className="bg-primary mb-5">
            <div className="container">
                <Navbar.Brand as={Link} to={adminUrl}>Admin - Panel</Navbar.Brand>
                <Navbar.Toggle aria-controls=".responsive-navbar-nav" />
                <Navbar.Collapse className="responsive-navbar-nav">
                    <Nav className="w-100">
                        <Nav.Link eventKey="1" as={Link} className="nav-item nav-link" to={adminProductsUrl}>Produkty</Nav.Link>
                        <Nav.Link eventKey="2" as={Link} className="nav-item nav-link" to={adminDiscountsUrl}>Rabaty</Nav.Link>
                        <Nav.Link eventKey="3" as={Link} className="nav-item nav-link" to={adminOrdersUrl}>Zamówienia</Nav.Link>
                        {/* <a className="nav-item nav-link" href="/produkty">Zarządzaj produktami</a>
                        <a className="nav-item nav-link" href="/kategorie">Zarządzaj kategoriami</a>
                        <a className="nav-item nav-link" href="/dodaj_konto">Dodaj administratora</a>
                        <a className="nav-item nav-link ml-lg-auto" href="/logout">Wyloguj</a> */}
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>

        <div className="container">
            <Outlet />
        </div>
    </>
}

export default Admin;