import { backendCreateProductUrl, backendDeleteProductUrl, backendGetProductsUrl, backendUpdateProductUrl } from "../urls";

export const getAllProducts = () => {
    return fetch(backendGetProductsUrl);
};

// export const getProductById = id => {
//     return products.filter(p => p.id == id)[0] ?? null;
// };

export const createProduct = product => {
    return fetch(backendCreateProductUrl, {
        method: "POST",
        body: JSON.stringify(product)
    });
}

export const updateProduct = product => {
    return fetch(backendUpdateProductUrl, {
        method: "POST",
        body: JSON.stringify(product)
    });
}

export const deleteProduct = id => {
    return fetch(`${backendDeleteProductUrl}/${id}`);
}
