import WelcomePopup from '../components/WelcomePopup/WelcomePopup';
import { useState } from "react";
import facebookIcon from "../resources/icons/facebook-icon.svg";
import pyszneIcon from "../resources/icons/pyszne-icon.svg";
import allegroIcon from "../resources/icons/allegro-icon.svg";
import olxIcon from "../resources/icons/olx-icon.svg";
import "./style.css";
import { allegroUrl, facebookUrl, menuUrl, olxUrl, pyszneUrl } from "../urls";
import slideMobile1 from '../resources/carouselMobile/slide1.jpg';
import slideMobile2 from '../resources/carouselMobile/slide2.jpg';
import slideMobile3 from '../resources/carouselMobile/slide3.jpg';
import slideMobile4 from '../resources/carouselMobile/slide4.jpg';
import slideDesktop1 from '../resources/carouselDesktop/slide1.jpg';
import slideDesktop2 from '../resources/carouselDesktop/slide2.jpg';
import slideDesktop3 from '../resources/carouselDesktop/slide3.jpg';
import slideDesktop4 from '../resources/carouselDesktop/slide4.jpg';
import Carousel from 'react-bootstrap/Carousel';

const ProductCarouselMobile = () => {
    return <Carousel className="ks-carousel d-lg-none">
        <Carousel.Item>
            <a href={menuUrl} className="ks-gotomenu">
                <img
                    className="ks-slide"
                    src={slideMobile1}
                    alt="Slajd"
                />
            </a>
        </Carousel.Item>
        <Carousel.Item>
            <a href={menuUrl} className="ks-gotomenu">
                <img
                    className="ks-slide"
                    src={slideMobile2}
                    alt="Slajd"
                />
            </a>
        </Carousel.Item>
        <Carousel.Item>
            <a href={menuUrl} className="ks-gotomenu">
                <img
                    className="ks-slide"
                    src={slideMobile3}
                    alt="Slajd"
                />
            </a>
        </Carousel.Item>
        <Carousel.Item>
            <a href={menuUrl} className="ks-gotomenu">
                <img
                    className="ks-slide"
                    src={slideMobile4}
                    alt="Slajd"
                />
            </a>
        </Carousel.Item>
    </Carousel>
};

const ProductCarouselDesktop = () => {
    return <Carousel className="ks-carousel d-none d-lg-block">
        <Carousel.Item>
            <a href={menuUrl} className="ks-gotomenu">
                <img
                    className="ks-slide"
                    src={slideDesktop1}
                    alt="Slajd"
                />
            </a>
        </Carousel.Item>
        <Carousel.Item>
            <a href={menuUrl} className="ks-gotomenu">
                <img
                    className="ks-slide"
                    src={slideDesktop2}
                    alt="Slajd"
                />
            </a>
        </Carousel.Item>
        <Carousel.Item>
            <a href={menuUrl} className="ks-gotomenu">
                <img
                    className="ks-slide"
                    src={slideDesktop3}
                    alt="Slajd"
                />
            </a>
        </Carousel.Item>
        <Carousel.Item>
            <a href={menuUrl} className="ks-gotomenu">
                <img
                    className="ks-slide"
                    src={slideDesktop4}
                    alt="Slajd"
                />
            </a>
        </Carousel.Item>
    </Carousel>
};

const SocialMediaWidget = ({ className }) => {
    return <div className={className}>
        <div>
            <a href={facebookUrl}>
                <img src={facebookIcon} alt="Facebook Icon" className="ks-social-media-icon" />
            </a>
        </div>
        <div>
            <a href={pyszneUrl}>
                <img src={pyszneIcon} alt="Pyszne Icon" className="ks-social-media-icon" />
            </a>
        </div>
        <div>
            <a href={allegroUrl}>
                <img src={allegroIcon} alt="Allegro Icon" className="ks-social-media-icon" />
            </a>
        </div>
        <div>
            <a href={olxUrl}>
                <img src={olxIcon} alt="OLX Icon" className="ks-social-media-icon" />
            </a>
        </div>
    </div>
};

const Home = () => {
    const [showPopup, setShowPopup] = useState(true);

    return <div className="ks-home">
        <WelcomePopup show={showPopup} onClose={() => setShowPopup(false)} />
        <SocialMediaWidget className="ks-social-media-mobile-container d-flex d-lg-none" />
        <ProductCarouselMobile />
        <ProductCarouselDesktop />
        <SocialMediaWidget className="ks-social-media-desktop-container d-none d-lg-flex" />
    </div>
};

export default Home;