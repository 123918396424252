export const homeUrl = "/",
    menuUrl = "/menu",
    aboutUsUrl = "/o-nas",
    cartUrl = "/koszyk",
    summaryUrl = "/podsumowanie",
    contactUrl = "/kontakt",
    formSubmittedUrl = "/wiadomosc-wyslana",
    backendUrl = process.env.REACT_APP_BACKEND_URL,
    backendProductsUrl = `${backendUrl}/products`,
    backendGetProductsUrl = `${backendProductsUrl}/get`,
    backendCreateProductUrl = `${backendProductsUrl}/create`,
    backendUpdateProductUrl = `${backendProductsUrl}/update`,
    backendDeleteProductUrl = `${backendProductsUrl}/delete`,
    backendUsersUrl = `${backendUrl}/users`,
    backendCreateUserUrl = `${backendUsersUrl}/create`,
    backendDeliveryMethodsUrl = `${backendUrl}/delivery`,
    backendGetDeliveryMethodsUrl = `${backendDeliveryMethodsUrl}/get`,
    backendCreateDeliveryMethodUrl = `${backendDeliveryMethodsUrl}/create`,
    backendUpdateDeliveryMethodUrl = `${backendDeliveryMethodsUrl}/update`,
    backendDeleteDeliveryMethodUrl = `${backendDeliveryMethodsUrl}/delete`,
    backendOrdersUrl = `${backendUrl}/orders`,
    backendGetOrdersUrl = `${backendOrdersUrl}/get`,
    backendCreateOrderUrl = `${backendOrdersUrl}/create`,
    backendUpdatePayUIdUrl = `${backendOrdersUrl}/update/payUId`,
    backendUpdateCompletedUrl = `${backendOrdersUrl}/update/completed`,
    backendOrderProductsUrl = `${backendUrl}/orderProducts`,
    backendCreateOrderProductsUrl = `${backendOrderProductsUrl}/create`,
    backendOrderDiscountsUrl = `${backendUrl}/orderDiscounts`,
    backendCreateOrderDiscountsUrl = `${backendOrderDiscountsUrl}/create`,
    backendPayUUrl = `${backendUrl}/payU`,
    backendCreatePayUOrderUrl = `${backendPayUUrl}/create`,
    backendCheckOutUrl = `${backendUrl}/checkOut`,
    backendCreateCheckOutUrl = `${backendCheckOutUrl}/create`,
    backendReCaptchaUrl = `${backendUrl}/reCaptcha`,
    backendReCaptchaVerifyUrl = `${backendReCaptchaUrl}/verify`,
    backendEmailUrl = `${backendUrl}/email`,
    backendEmailContactFormUrl = `${backendEmailUrl}/contactForm`,
    backendEmailConfirmOrderUrl = `${backendEmailUrl}/confirmOrder`,
    backendEmailNotifyOrderUrl = `${backendEmailUrl}/notifyOrder`,
    backendDiscountsUrl = `${backendUrl}/discounts`,
    backendGetDiscountsUrl = `${backendDiscountsUrl}/get`,
    backendCreateDiscountUrl = `${backendDiscountsUrl}/create`,
    backendDeleteDiscountUrl = `${backendDiscountsUrl}/delete`,
    adminUrl = "/ca4fbfc7",
    adminProductsUrl = `${adminUrl}/produkty`,
    adminDiscountsUrl = `${adminUrl}/rabaty`,
    adminOrdersUrl = `${adminUrl}/zamowienia`,
    facebookUrl = "https://www.facebook.com/profile.php?id=100087836283898",
    allegroUrl = "https://allegro.pl/uzytkownik/KrakowskieSloiki",
    pyszneUrl = "https://www.pyszne.pl/menu/krakowskie-sloiki",
    olxUrl = "https://www.olx.pl/d/oferty/uzytkownik/1w9fU5/";
