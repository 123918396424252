import { Link } from 'react-router-dom';
import cartIcon from '../../resources/icons/cart-icon.svg';
import './style.css';

const CartIcon = ({ itemsCount, ...props }) => {
    const className = `ks-cart-icon ${props.className ?? ""}`
    return <Link {...props} className={className}>
        <div>
            <div className="ks-items-count bg-danger">{itemsCount}</div>
            <img src={cartIcon} alt="Cart Icon" className="ks-nav-link-icon" />
        </div>
    </Link>
}

export default CartIcon;