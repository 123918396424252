import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { sendForm } from "../dataProviders/emailProvider";
import { verifyReCaptcha } from "../dataProviders/reCaptchaProvider";
import { formSubmittedUrl } from "../urls";

const Contact = () => {
    const [verified, setVerified] = useState(false);
    const [form, setForm] = useState({
        name: "",
        email: "",
        content: "",
    })
    const captchaRef = useRef(null);
    const captchaWarningRef = useRef(null);

    const onReCaptchaChange = token => {
        verifyReCaptcha(token)
            .then(res => setVerified(res.ok))
            .catch(() => setVerified(false));
    }

    const onSubmit = () => {
        if (verified) {
            captchaWarningRef.current.className = "d-none";
            sendForm(form).then(res => res.ok ? window.location = formSubmittedUrl : alert("Wystąpił błąd."));
        } else
            captchaWarningRef.current.className = "d-block";
    };

    return <div className="ks-contact p-5">
        <div className="card mb-4">
            <div className="card-header">
                <h5 className="card-title">Kontakt</h5>
            </div>
            <div className="card-body p-3">
                <h5 className="card-title">Email:</h5>
                <a href="mailto:kontakt@krakowskie-sloiki.pl" className="link-danger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                    </svg>
                    kontakt@krakowskie-sloiki.pl
                </a>
                <h5 className="card-title mt-3">Telefon:</h5>
                <a href="tel:+48530999830" className="link-danger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                    530 999 830
                </a>
            </div>
        </div>
        <div className="card mb-4">
            <div className="card-header">
                <h5 className="card-title">Skontaktuj się z nami</h5>
            </div>
            <div className="card-body px-3">
                <form>
                    <div className="form-group row mb-5">
                        <label htmlFor="name" className="col-sm-4 col-form-label">
                            Imię i nazwisko:
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                value={form.name}
                                onChange={e => setForm(prev => ({ ...prev, name: e.target.value }))}
                                required
                            /><br />
                        </div>
                    </div>
                    <div className="form-group row mb-5">
                        <label htmlFor="email" className="col-sm-4 col-form-label">Adres email:</label>
                        <div className="col-sm-8">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                value={form.email}
                                onChange={e => setForm(prev => ({ ...prev, email: e.target.value }))}
                                required
                            /><br />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-8">
                            <textarea
                                name="content"
                                rows="6"
                                className="form-control mb-3"
                                placeholder="Wpisz treść wiadomości"
                                value={form.content}
                                onChange={e => setForm(prev => ({ ...prev, content: e.target.value }))}
                                required
                            />
                        </div>
                    </div>
                    <div className="d-none" ref={captchaWarningRef}>
                        <p className='fw-bold text-danger'>Uzupełnij</p>
                    </div>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC}
                        ref={captchaRef}
                        onChange={onReCaptchaChange}
                        className="mb-3"
                    />
                    <button
                        type="submit"
                        name="send_message"
                        className="btn btn-danger"
                        onClick={e => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Wyślij wiadomość
                    </button>
                </form>
            </div>
        </div>
    </div>
};

export default Contact;