import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "../../components/Table/Table";
import { getAllOrders, updateOrderCompleted } from "../../dataProviders/ordersProvider";

const ChangeCompletedButton = ({ order, setOrders, completed, variant, children }) => (
    <Button
        variant={variant}
        onClick={() => updateOrderCompleted({ ...order, completed })
            .then(res => res.json())
            .then(json => setOrders(prev => prev.map(o => (o.id === order.id ? { ...o, completed } : o
            ))))
        }
    >
        {children}
    </Button >
);

const PaymentStatusLabel = ({ children }) => {
    const status = children ?? "NULL";
    const statusColorMap = {
        COMPLETED: "text-success",
        PENDING: "text-warning",
        WAITING_FOR_CONFIRMATION: "text-warning",
        CANCELED: "text-danger",
        NULL: "text-danger"
    };
    return <p className={statusColorMap[status]}>
        <b>{status}</b>
    </p>
};

export const Orders = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getAllOrders()
            .then(res => res.json())
            .then(json => setOrders(json.response));
    }, [])

    return <div>
        {/* <form action="/admin/zamowienia" method="GET" className="form-group mb-5">
            <div className="row">
                <select name="status" id="statusFilter" className="mr-3">
      <?php
        if (isset($_GET['status']) && $_GET['status'] == 0) {
          print '
            <option value="2">Wszystkie</option>
            <option value="1">Zrealizowane</option>
            <option value="0" selected="selected">Niezrealizowane</option>';
        }
        elseif (isset($_GET['status']) && $_GET['status'] == 1) {
          print '
            <option value="2">Wszystkie</option>
            <option value="1" selected="selected">Zrealizowane</option>
            <option value="0">Niezrealizowane</option>';
        }
        elseif (isset($_GET['status']) && $_GET['status'] == 2) {
          print '
            <option value="2" selected="selected">Wszystkie</option>
            <option value="1">Zrealizowane</option>
            <option value="0">Niezrealizowane</option>';
        }
        else {
          print '
            <option value="2">Wszystkie</option>
            <option value="1">Zrealizowane</option>
            <option value="0">Niezrealizowane</option>';
        }
      ?>
    </select>
                <input type="text" name="search" className="mr-3" placeholder="Szukaj ..." value="" />
                <input type="number" name="limit" className="mr-3" placeholder="Limit ..." min="0" value="" />
                <button className="btn btn-primary">Pokaż</button>
            </div>
        </form> */}
        <Table
            header={[
                "ID",
                "Data",
                "Klient",
                "Zamówienie",
                "Dostawa",
                "Suma",
                "Status płatności",
                "Zrealizowano"
            ]}
        >
            {orders.map(order => ({
                id: order.id.toString(),
                elements: [
                    order.id,
                    order.timestamp,
                    order.user.map(x => <>{x}<br /></>),
                    order.products.map(x => <>{x}<br /></>),
                    order.delivery,
                    `${(order.total / 100).toFixed(2)} zł`,
                    <PaymentStatusLabel>
                        {order.paymentStatus}
                    </PaymentStatusLabel>,
                    order.completed ?
                        <ChangeCompletedButton
                            variant={"danger"}
                            completed={0}
                            order={order}
                            setOrders={setOrders}
                        >
                            Anuluj
                        </ChangeCompletedButton>
                        :
                        <ChangeCompletedButton
                            variant={"success"}
                            completed={1}
                            order={order}
                            setOrders={setOrders}
                        >
                            Zrealizowano
                        </ChangeCompletedButton>
                ]
            })
            )}
        </Table>
    </div >
}