import { useRef } from "react";
import "./style.css";

const Popup = ({ show, handleClose, className, children }) => {
  const overlayRef = useRef();

  return (
    <div
      ref={overlayRef}
      className="popupOverlay"
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? 1 : 0,
      }}
      onClick={e => {
        if (e.target === overlayRef.current) {
          handleClose();
        }
      }}
    >
      <div className={`popupContainer ${className ?? ""}`}>
        <span className="popupClose" onClick={handleClose}>
          &times;
        </span>
        <div className="popupContent">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
