import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { getAllProducts } from "../dataProviders/productsProvider";
import Product from "../components/Product/Product";
import "./style.css";
import ProductPopup from "../components/ProductPopup/ProductPopup";
import Popup from "../components/Popup/Popup";
import { cartUrl } from "../urls";

const GoToCartPopup = ({ show, handleClose }) => {
    return <Popup
        show={show}
        handleClose={handleClose}
        className="h-auto"
    >
        <div className="p-3">
            <h3 className="my-5 text-center">Twoje zamówienie zostało dodane do koszyka.</h3>
            <div className="d-flex justify-content-center">
                <Link to={cartUrl} className="btn btn-outline-dark mx-5">Idź do koszyka</Link>
                <Button
                    variant="outline-dark"
                    className="mx-5"
                    onClick={handleClose}
                >
                    Kontynuuj zakupy
                </Button>
            </div>
        </div>
    </Popup>
};

const Shop = ({ onAddToCart }) => {
    const [showProductPopup, setShowProductPopup] = useState(false);
    const [showGoToCartPopup, setShowGoToCartPopup] = useState(false);
    const [selectedPropductId, setSelectedProductId] = useState(0);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getAllProducts()
            .then(res => res.json())
            .then(json => setProducts(json.response));
    }, []);

    // Product popup helper functions
    const openProductPopup = id => {
        document.body.style.overflow = "hidden";
        setSelectedProductId(id);
        setShowProductPopup(true);
    };

    const closeProductPopup = () => {
        document.body.style.overflow = "visible";
        setShowProductPopup(false);
    };

    return <div className="ks-shop">
        <GoToCartPopup
            show={showGoToCartPopup}
            handleClose={() => setShowGoToCartPopup(false)}
        />
        <ProductPopup
            product={products.filter(p => p.id === selectedPropductId)[0]}
            show={showProductPopup}
            handleClose={closeProductPopup}
            onAddToCart={product => {
                onAddToCart(product);
                closeProductPopup();
                setShowGoToCartPopup(true);
            }}
        />
        <Row className="ks-products-row">
            {products.map(product => (
                <Col xs={12} lg={6} className="p-5 ks-product-container mb-5">
                    <Product
                        className="ks-product-wrapper"
                        product={product}
                        onAddToCart={product => {
                            onAddToCart(product);
                            setShowGoToCartPopup(true);
                        }}
                        onClick={() => openProductPopup(product.id)}
                    />
                </Col>
            ))}
        </Row>
    </div>
};

export default Shop;
