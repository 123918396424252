import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Popup from "../Popup/Popup";
import logo from "../../resources/logo_boundry.svg";
import adHorizontal from '../../resources/ads/promocja_4x3.jpg';
import { menuUrl } from "../../urls";

const WelcomePopup = ({ show, onClose }) => {
    return <Popup show={show} handleClose={onClose}>
        <div className="p-3">
            <img src={logo} width="200" className="d-block py-5 mx-auto" alt="Logo" />
            <h1 className="text-center pb-3">Promocja na 6 dowolnych produktów!</h1>
            <p className="px-lg-5">
                Zachęcamy do skorzystania z naszej promocji.
                Już od 6 dowolnych produktów możesz liczyć na zniżkę w wysokości 10%.
                <br /><br />
                Ciesz się smakiem naszych potraw i darmową wysyłką przy pierwszym zamówieniu.
                Sprawdź nasze menu i spróbuj naszych wyrobów.
            </p>
            <Link to={menuUrl} className="link-danger text-decoration-none">
                <h3 className="text-center">Przejdź do menu</h3>
                <img src={adHorizontal} width="400" className="d-block mw-100 pt-3 mx-auto" alt="Banner" />
            </Link>
            <Button variant="danger" className="d-block mt-5 mx-auto" onClick={onClose}>
                Zamknij
            </Button>
        </div>
    </Popup>;
};

export default WelcomePopup;