import { Table as BTable } from 'react-bootstrap';

const Table = ({ header, onClick, children }) => {
    return <BTable striped bordered hover>
        <thead className="serial sticky-top bg-light" style={{ boxShadow: "inset 0 -2px 0 #000000" }}>
            <tr>
                {header.map((h, i) => <th key={i}>{h}</th>)}
            </tr>
        </thead>
        <tbody>
            {children.map((obj, i) => <tr key={i} data-key={obj.id} onClick={onClick}>
                {obj.elements.map((col, j) => <td key={j}>{col}</td>)}
            </tr>)}
        </tbody>
    </BTable>
}

export default Table;