import { backendCreateDiscountUrl, backendCreateOrderDiscountsUrl, backendDeleteDiscountUrl, backendGetDiscountsUrl } from "../urls";

export const getDiscounts = () => {
    return fetch(backendGetDiscountsUrl);
}

export const createDiscount = discount => {
    return fetch(backendCreateDiscountUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(discount)
    });
}

export const deleteDiscount = id => {
    return fetch(`${backendDeleteDiscountUrl}/${id}`);
}

export const createOrderDiscounts = orderDiscounts => {
    return fetch(backendCreateOrderDiscountsUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(orderDiscounts)
    });
};
