import { Card, Col, Row } from "react-bootstrap";
import assortment from "../resources/asortyment.jpeg";
import "./style.css";

const AboutUs = () => {
    return <div className="ks-about-us p-5">
        <Card>
            <Card.Header>
                <Card.Title>
                    Krótko o nas...
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col lg={6}>
                        <Card.Text>
                            <br />
                            Jak sama nazwa wskazuje jesteśmy z Krakowa. Pojawiliśmy się z naszymi produktami chcąc podzielić się swoją pasją do gotowania i pysznej kuchni, takiej którą pamiętamy z dzieciństwa. Potrawy  które dawniej gościły na stołach i smaki dzięki którym możemy sobie przypomnieć o ludziach, miejscach i miłych chwilach z naszego życia. Otwierając nasz słoik przeniesiesz się w krainę smaku i zapachu. Juz nie musisz tęsknić za tym co było! My dajemy Ci to tu i teraz, prosto pod Twoje drzwi na Twój stół, bez konserwantów, bez sztucznych barwników na najlepszych, specjalnie wyselekcjonowanych półproduktach. Odkręcając nasz słoik oszczędzasz swój czas!
                            Wszystkie produkty z naszej oferty są poddane procesowi TYNDALIZACJI dzięki czemu mają dłuższy okres przydatności do spożycia a nie wymagają warunków chłodzenia.
                            <br /><br />
                            Krakowskie Słoiki
                        </Card.Text>
                    </Col>
                    <Col lg={6}>
                        <img src={assortment} alt="Asortyment" />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </div>
};

export default AboutUs;