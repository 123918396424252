import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import Cart from './Cart/Cart';
import NavBar from './components/NavBar/NavBar';
import Home from './Home/Home';
import { Orders } from './Admin/Orders/Orders';
import Shop from './Shop/Shop';
import Summary from './Summary/Summary';
import Admin from './Admin/Admin';
import Products from './Admin/Products/Products';
import AboutUs from './AboutUs/AboutUs';
import Contact from './Contact/Contact';
import FormSubmitted from './FormSubmitted/FormSubmitted';
import Discounts from './Admin/Discounts/Discounts';

const AppRender = ({ cartItems }) => {
  return <div className="app">
    <NavBar itemsCount={Object.keys(cartItems).length} />
    <div className="ks-content">
      <Outlet />
    </div>
  </div>
}

function getCart() {
  if (!localStorage.getItem('appVersion')) {
    localStorage.setItem('appVersion', '1.1');
    localStorage.removeItem('cart');
  }
  return localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {};
}

function addToCart(cart, product) {
  const productOrder = cart[product.id] ?? { product: product, cnt: 0 };
  return { ...cart, [product.id]: { ...productOrder, cnt: productOrder.cnt + 1 } };
}

function App() {
  const [cartItems, setCartItems] = useState(getCart());

  const clearCart = useCallback(() => {
    localStorage.clear('cart');
    setCartItems({});
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="ca4fbfc7" element={<Admin />}>
          <Route path="zamowienia" element={<Orders />} />
          <Route path="rabaty" element={<Discounts />} />
          <Route path="produkty" element={<Products />} />
        </Route>
        <Route element={<AppRender cartItems={cartItems} />}>
          <Route index element={<Home />} />
          <Route
            path="menu"
            element={
              <Shop onAddToCart={product => setCartItems(prev => addToCart(prev, product))} />
            }
          />
          <Route path="o-nas" element={<AboutUs />} />
          <Route path="kontakt" element={<Contact />} />
          <Route path="koszyk" element={<Cart cart={cartItems} setCart={setCartItems} />} />
          <Route path="podsumowanie">
            <Route path=":id" element={<Summary clearCart={clearCart} />} />
          </Route>
          <Route path="wiadomosc-wyslana" element={<FormSubmitted />} />
        </Route>
      </Routes>
    </BrowserRouter >
  );
}

export default App;
