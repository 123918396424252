import { backendCreateOrderUrl, backendGetOrdersUrl, backendUpdateCompletedUrl, backendUpdatePayUIdUrl } from "../urls";

export const getAllOrders = () => {
    return fetch(backendGetOrdersUrl);
}

export const createOrder = order => {
    return fetch(backendCreateOrderUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(order)
    });
};

export const updateOrderPayUId = order => {
    return fetch(backendUpdatePayUIdUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(order)
    })
}

export const updateOrderCompleted = order => {
    return fetch(backendUpdateCompletedUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(order)
    })
}
