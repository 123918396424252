import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Popup from "../Popup/Popup";
import cartIcon from '../../resources/icons/cart-icon.svg';
import "./style.css";

const ProductPopup = ({ product, show, handleClose, onAddToCart }) => {
  const [preview, setPreview] = useState();
  const [_product, set_product] = useState({
    name: "",
    description: "",
    price: 0,
    thumbnail: "",
    images: []
  });

  useEffect(() => {
    set_product(product ?? {
      name: "",
      description: "",
      price: 0,
      thumbnail: "",
      images: []
    });
  }, [product])

  useEffect(() => {
    setPreview(_product.thumbnail);
  }, [_product]);

  return (
    <Popup show={show} handleClose={handleClose}>
      <Row className="productPopupContainer">
        <Col md={5} className="imageContainer mb-5 mb-md-0">
          <div className="previewWrap imageThumbnail">
            <img src={preview} alt="Preview" />
          </div>
          <div className="thumbnailsWrap">
            {_product.images.map((thumbnail, i) => (
              <button
                key={i}
                className="thumbnail"
                onClick={() => setPreview(thumbnail)}
              >
                <img src={thumbnail} alt="thumbnail" />
              </button>
            ))}
          </div>
          <Button
            variant="dark"
            className="d-flex"
            onClick={e => {
              e.preventDefault();
              onAddToCart(_product);
            }}
          >
            <p className="m-0">Dodaj do koszyka</p>
            <img src={cartIcon} alt="Cart Icon" className="ks-cart-icon" />
          </Button>
        </Col>
        <Col md={7}>
          <div className="descriptionContainer">
            <h2 className="productTitle">{_product.name}</h2>
            <h5 className="productPrice">{(_product.price / 100).toFixed(2)} zł</h5>
            <p className="productDescription">{_product.description}</p>
          </div>
        </Col>
      </Row>
    </Popup>
  );
};

export default ProductPopup;
