import { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Popup from "../components/Popup/Popup";
import { createCheckOut } from "../dataProviders/checkOutProvider";
import { getAllDeliveryMethods } from "../dataProviders/deliveryProvider";
import { getDiscounts } from "../dataProviders/discountsProvider";
import "./style.css";

const InPostGeoWidget = ({ show, onClose, onSelect }) => {
    useEffect(() => document.addEventListener('onpointselect', onSelect), [onSelect]);

    return <Popup show={show} handleClose={onClose} className="p-5">
        <inpost-geowidget onpoint="onpointselect" token={process.env.REACT_APP_INPOST_GEOWIDGET_TOKEN} language='pl' config='parcelcollect'></inpost-geowidget>
    </Popup>
}

const filterDiscounts = (discounts, cart, deliveryId, discountCode) => {
    const quantity = Object.values(cart)
        .map(p => p.cnt)
        .reduce((acc, cnt) => acc + cnt, 0);
    const subTotal = Object.values(cart)
        .map(p => p.cnt * p.product.price)
        .reduce((acc, price) => acc + price, 0);
    let filteredDiscounts = [];

    // Delivery discount
    const deliveryDiscount = discounts.find(d =>
        d.type === 'delivery' &&
        d.delivery_ids.includes(deliveryId) &&
        quantity >= d.min_quantity &&
        subTotal >= d.min_amount);
    if (deliveryDiscount) {
        filteredDiscounts.push(deliveryDiscount)
    }

    // Products discounts
    Object.values(cart).forEach(product => {
        const productDiscount = discounts.find(d =>
            d.type === 'product' &&
            d.product_ids.includes(product.id) &&
            quantity >= d.min_quantity &&
            subTotal >= d.min_amount);
        if (productDiscount) {
            filteredDiscounts.push(productDiscount);
        }
    })

    // Quantity and amount discounts
    const otherDiscounts = discounts.filter(d =>
        ['amount', 'quantity'].includes(d.type) &&
        quantity >= d.min_quantity &&
        subTotal >= d.min_amount)
        .sort((d1, d2) => calaculateDiscount(d1, subTotal) - calaculateDiscount(d2, subTotal))
        .at(-1);
    if (otherDiscounts) {
        filteredDiscounts.push(otherDiscounts);
    }

    // Quantity and amount discounts
    const codeDiscount = discounts.find(d =>
        d.type === 'code' &&
        d.code === discountCode &&
        quantity >= d.min_quantity &&
        subTotal >= d.min_amount)
    if (codeDiscount) {
        filteredDiscounts.push(codeDiscount);
    }

    return filteredDiscounts;
}

const calculateTotal = (cart, deliveryPrice, discounts) => {
    const subTotal = Object.values(cart).reduce((acc, x) => acc + (x.cnt * x.product.price), 0);
    const discountValue = discounts.map(d => calaculateDiscount(d, subTotal))
        .reduce((ans, d) => ans + d, 0);
    return subTotal + deliveryPrice - discountValue;
}

const calaculateDiscount = (d, subTotal) => {
    return d.value_type === 'amount' ? d.value : subTotal * d.value / 100
}

const Cart = ({ cart, setCart }) => {
    const [showParcelWidget, setShowParcelWidget] = useState(false);
    const [showParcelInput, setShowParcelInput] = useState(false);
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [allDiscounts, setAllDiscounts] = useState([]);
    const [order, setOrder] = useState({
        delivery: {
            id: 0,
            name: "",
            price: 0
        },
        address: {
            firstName: "",
            surname: "",
            phone: "",
            email: "",
            street: "",
            city: "",
            zipCode: "",
            paczkomat: ""
        },
        discounts: [],
        discountCode: "",
        total: 0
    });
    const discountInput = useRef();

    // Fetch delivery methods and discounts
    useEffect(() => {
        getAllDeliveryMethods()
            .then(res => res.json())
            .then(json => setDeliveryMethods(json['response']))
            .catch(console.log);
        getDiscounts()
            .then(res => res.json())
            .then(json => setAllDiscounts(json['response']))
            .catch(console.log);
    }, []);

    // Set default delivery method
    useEffect(() => {
        if (deliveryMethods[0]) {
            setOrder(prev => ({ ...prev, delivery: deliveryMethods[0] }));
        }
    }, [deliveryMethods]);

    // Update discounts
    useEffect(() => {
        setOrder(prev => ({ ...prev, discounts: filterDiscounts(allDiscounts, cart, order.delivery.id, order.discountCode) }))
    }, [cart, order.delivery.id, order.discountCode, allDiscounts]);

    // Calculate total
    useEffect(() => {
        setOrder(prev => ({ ...prev, total: calculateTotal(cart, prev.delivery.price, prev.discounts) }));
    }, [cart, order.delivery.id, order.discounts]);

    // Show parcel map
    useEffect(() => {
        if (order.delivery.id === 6) {
            setShowParcelWidget(true);
            setShowParcelInput(true);
        } else {
            setShowParcelInput(false);
            setOrder(prev => ({ ...prev, address: { ...prev.address, paczkomat: "" } }));
        }
    }, [cart, order.delivery.id]);

    // Is discount code valid
    useEffect(() => {
        discountInput.current.classList.remove('is-valid');
        discountInput.current.classList.remove('is-invalid');
        if (!order.discountCode) {
            return;
        }
        if (order.discounts.find(d => d.code === order.discountCode)) {
            discountInput.current.classList.add('is-valid');
        } else {
            discountInput.current.classList.add('is-invalid');
        }
    }, [order.discounts, order.discountCode]);

    const updateCartCnt = (id, diff) => {
        setCart(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                cnt: Math.max(1, Math.min(99, prev[id].cnt + diff))
            }
        }));
    }

    const deleteFromCart = id => setCart(({ [id]: value, ...prev }) => prev);

    const handleSubmit = async () => {
        let valid = true;
        Object.entries(order.address).forEach(([key, value]) => {
            if (key === "paczkomat" && !showParcelInput) {
            } else if (!value) {
                document.getElementsByName(key)[0].classList.add("is-invalid");
                valid = false;
            } else {
                document.getElementsByName(key)[0].classList.remove("is-invalid");
            }
        });
        if (valid) {
            try {
                const paymentRes = await createCheckOut({ ...order, cart }).then(res => res.json());
                window.location = paymentRes.response.redirectUri;
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleDiscountInputClick = () => {
        const discountCode = discountInput.current.value;
        setOrder(prev => ({ ...prev, discountCode: discountCode }));
    }

    return <>
        <InPostGeoWidget
            show={showParcelWidget}
            onClose={() => setShowParcelWidget(false)}
            onSelect={e => {
                setOrder(prev => ({ ...prev, address: { ...prev.address, paczkomat: e.detail.name } }));
                setShowParcelWidget(false);
            }}
        />
        <section className="ks-cart h-100">
            <div className="container h-100 py-5">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-10">

                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h3 className="fw-normal mb-0 text-black">Koszyk</h3>
                            {/* <div>
                            <p className="mb-0"><span className="text-muted">Sortuj:</span> <a href="#!" className="text-body">
                                cena <i className="fas fa-angle-down mt-1"></i></a>
                            </p>
                        </div> */}
                        </div>

                        {Object.entries(cart).map(([id, { product, cnt }]) => {
                            return <div key={id} className="card rounded-3 mb-4">
                                <div className="card-body p-4">
                                    <div className="row d-flex justify-content-between align-items-center">
                                        <div className="col-md-2 col-lg-2 col-xl-2">
                                            <img
                                                src={product.thumbnail}
                                                className="ks-product-thumbnail img-fluid rounded-3" alt="Thumbnail" />
                                        </div>
                                        <div className="col-md-3 col-lg-3 col-xl-3 text-center">
                                            <p className="lead fw-normal mb-2">{product.name}</p>
                                            {/* <p><span className="text-muted">Size: </span>M <span className="text-muted">Color: </span>Grey</p> */}
                                        </div>
                                        <div className="col-md-3 col-lg-3 col-xl-3 d-flex">
                                            <button
                                                className="btn btn-outline-danger border-0 mx-3"
                                                onClick={() => updateCartCnt(id, -1)}
                                            >
                                                <i className="fas fa-minus fa-lg" />
                                            </button>
                                            <input
                                                id="form1"
                                                min="1"
                                                max="99"
                                                name="quantity"
                                                type="number"
                                                className="ks-quantity form-control form-control-sm"
                                                value={cnt}
                                            />
                                            <button
                                                className="btn btn-outline-danger border-0 mx-3"
                                                onClick={() => updateCartCnt(id, 1)}
                                            >
                                                <i className="fas fa-plus fa-lg" />
                                            </button>
                                        </div>
                                        <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1 mt-3 mt-s-0 text-center">
                                            <h5 className="mb-0">{(product.price / 100).toFixed(2)} zł</h5>
                                        </div>
                                        <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                            <span
                                                className="ks-trash text-danger"
                                                onClick={() => deleteFromCart(id)}
                                            >
                                                <i className="fas fa-trash fa-lg" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}

                        {/* <div className="card mb-4">
                        <div className="card-body p-4 d-flex flex-row">
                            <div className="form-outline flex-fill">
                                <input type="text" id="form1" className="form-control form-control-lg" />
                                <label className="form-label" for="form1">Kod zniżkowy</label>
                            </div>
                            <button type="button" className="btn btn-outline-danger btn-lg ms-3">Zastosuj</button>
                        </div>
                    </div> */}

                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h3 className="fw-normal mb-0 text-black">Dostawa</h3>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                {/* <p className="mb-2"><em>Przy zakupie 6 szt. dostawa gratis.</em></p> */}
                                <p className="mb-2"><em>Dla nowych klientów wysyłka gratis!</em></p>
                                {deliveryMethods.map(delivery => (
                                    <div className="form-outline flex-fill" key={delivery.id}>
                                        <Form>
                                            <div className="mb-3">
                                                <Form.Check
                                                    type="radio"
                                                    label={`${delivery.name} - ${(delivery.price / 100).toFixed(2)} zł`}
                                                    checked={delivery.id === order.delivery.id}
                                                    onChange={() => setOrder(prev => ({
                                                        ...prev,
                                                        delivery: deliveryMethods.find(d => d.id === delivery.id)
                                                    }))}
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h3 className="fw-normal mb-0 text-black">Rabaty</h3>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row mb-4">
                                    <div className="col-9 col-md-6">
                                        <input
                                            ref={discountInput}
                                            type="text"
                                            name="discount"
                                            id="discount"
                                            className="form-control form-control-md"
                                            placeholder="Kod"
                                        />
                                    </div>
                                    <div className="col-3 col-md-6">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-block btn-md"
                                            onClick={handleDiscountInputClick}
                                        >
                                            Zastosuj
                                        </button>
                                    </div>
                                </div>
                                {order.discounts.map(d => (
                                    <div className="mb-3" key={d.id}>
                                        {d.name} - {d.value_type === "amount" ? `${(d.value / 100).toFixed(2)} zł` : `${d.value}%`}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <h3 className="fw-normal mb-0 text-black">Twoje dane</h3>
                                        </div>

                                        <div className="ks-address-form form-outline flex-fill">
                                            <label className="form-label" htmlFor="firstName">Imię</label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                className="form-control form-control-lg"
                                                value={order.address.firstName}
                                                onChange={e => setOrder(prev => ({ ...prev, address: { ...prev.address, firstName: e.target.value } }))}
                                            />
                                            <label className="form-label" htmlFor="surname">Nazwisko</label>
                                            <input
                                                type="text"
                                                name="surname"
                                                id="surname"
                                                className="form-control form-control-lg"
                                                value={order.address.surname}
                                                onChange={e => setOrder(prev => ({ ...prev, address: { ...prev.address, surname: e.target.value } }))}
                                            />
                                            <label className="form-label" htmlFor="email">Email</label>
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                className="form-control form-control-lg"
                                                value={order.address.email}
                                                onChange={e => setOrder(prev => ({ ...prev, address: { ...prev.address, email: e.target.value } }))}
                                            />
                                            <label className="form-label" htmlFor="phone">Telefon</label>
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                className="form-control form-control-lg"
                                                value={order.address.phone}
                                                onChange={e => setOrder(prev => ({ ...prev, address: { ...prev.address, phone: e.target.value } }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <h3 className="fw-normal mb-0 text-black">Adres</h3>
                                        </div>

                                        <div className="ks-address-form form-outline flex-fill">
                                            <label className="form-label" htmlFor="street">Ulica i numer</label>
                                            <input
                                                type="text"
                                                name="street"
                                                id="street"
                                                className="form-control form-control-lg"
                                                value={order.address.street}
                                                onChange={e => setOrder(prev => ({ ...prev, address: { ...prev.address, street: e.target.value } }))}
                                            />
                                            <label className="form-label" htmlFor="city">Miasto</label>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                className="form-control form-control-lg"
                                                value={order.address.city}
                                                onChange={e => setOrder(prev => ({ ...prev, address: { ...prev.address, city: e.target.value } }))}
                                            />
                                            <label className="form-label" htmlFor="zipCode">Kod pocztowy</label>
                                            <input
                                                type="text"
                                                name="zipCode"
                                                id="zipCode"
                                                className="form-control form-control-lg"
                                                value={order.address.zipCode}
                                                onChange={e => setOrder(prev => ({ ...prev, address: { ...prev.address, zipCode: e.target.value } }))}
                                            />
                                            {showParcelInput && <>
                                                <label className="form-label" htmlFor="paczkomat">Paczkomat</label>
                                                <input
                                                    value={order.address.paczkomat}
                                                    onClick={() => setShowParcelWidget(true)}
                                                    type="button"
                                                    name="paczkomat"
                                                    id="paczkomat"
                                                    className="form-control form-control-lg"
                                                />
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card rounded-3 mb-4">
                            <div className="card-body p-4">
                                <p>Suma: {(order.total / 100).toFixed(2)} zł</p>
                            </div>
                        </div>

                        <button
                            type="button"
                            className="btn btn-danger btn-block btn-lg"
                            onClick={handleSubmit}
                        >
                            Przejdź do płatności
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </>
};

export default Cart;