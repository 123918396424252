import { useEffect, useState } from "react";
import { Button, Card, Col, Collapse, Row } from "react-bootstrap";
import Popup from "../../components/Popup/Popup";
import Table from "../../components/Table/Table";
import { createProduct, deleteProduct, getAllProducts, updateProduct } from "../../dataProviders/productsProvider";
import "./style.css";

const AddProductWidget = ({ open, product, onSubmit, className }) => {
    const [newProduct, setNewProduct] = useState({
        name: "",
        description: "",
        price: 0,
        thumbnail: "",
        thumbnailHover: "",
        images: []
    });

    useEffect(() => {
        setNewProduct(prev => ({ ...prev, ...product }));
    }, [product]);

    return <Collapse in={open ?? true}>
        <Card className={`mb-3 ${className}`}>
            <Card.Body>
                <Row>
                    <Col lg={6} className="form-group mb-3">
                        <label>Nazwa:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={newProduct.name}
                            onChange={e => setNewProduct(prev => ({ ...prev, name: e.target.value }))}
                            required
                        />
                    </Col>
                    <Col lg={6} className="form-group mb-3">
                        <label>Cena w groszach:</label>
                        <input
                            type="number"
                            className="form-control"
                            value={newProduct.price}
                            onChange={e => setNewProduct(prev => ({ ...prev, price: parseInt(e.target.value) }))}
                            required
                        />
                    </Col>
                </Row>
                <Row className="row">
                    <Col className="form-group mb-3">
                        <label>Opis:</label>
                        <textarea
                            className="form-control"
                            rows="8"
                            cols="30"
                            value={newProduct.description}
                            onChange={e => setNewProduct(prev => ({ ...prev, description: e.target.value }))}
                        />
                    </Col>
                    <Col className="form-group mb-3">
                        <Row>
                            <label>Miniaturka:</label>
                        </Row>
                        <Row>
                            <input type="file" className="form-control-file mb-2" />
                        </Row>
                        <Row>
                            <label>Obrazy:</label>
                        </Row>
                        <Row>
                            <input type="file" className="form-control-file mb-2" multiple />
                        </Row>
                    </Col>
                </Row>
                <Button
                    variant="primary"
                    onClick={() => {
                        let thumbnails = [];
                        if (product && product.thumbnail)
                            thumbnails = [...thumbnails, product.thumbnail];
                        if (product && product.thumbnailHover)
                            thumbnails = [...thumbnails, product.thumbnailHover];
                        const success = onSubmit({ ...newProduct, thumbnails });
                        if (success)
                            setNewProduct({
                                name: "",
                                description: "",
                                price: 0,
                                thumbnail: "",
                                thumbnailHover: "",
                                images: []
                            });
                    }}
                >
                    Zapisz
                </Button>
            </Card.Body>
        </Card>
    </Collapse>
};

const DeleteButton = ({ setProducts, children }) => {
    return <Button
        variant="danger"
        className="ks-delete-button"
        onClick={() => {
            deleteProduct(children.id)
                .then(res => res.json())
                .then(json => {
                    if (json.status === "success")
                        setProducts(prev => prev.filter(p => p.id !== children.id));
                    else
                        console.log(json);
                })
        }}
    >
        Usuń
    </Button>
};

const EditProductPopup = ({ product, show, onClose, onSubmit }) => {
    return <Popup show={show} handleClose={onClose}>
        <AddProductWidget
            product={product}
            onSubmit={onSubmit}
            className="mt-5"
        />
    </Popup>;
};

const Products = () => {
    const [products, setProducts] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [showAddProduct, setShowAddProduct] = useState(false);
    const [showEditProduct, setShowEditProduct] = useState(false);
    const selectedProduct = products.find(p => p.id === selectedProductId);

    useEffect(() => {
        getAllProducts()
            .then(res => res.json())
            .then(json => setProducts(json.response));
    }, []);

    const handleProductClick = e => {
        const id = parseInt(e.currentTarget.getAttribute('data-key'));
        setSelectedProductId(id);
        setShowEditProduct(true);
    }

    const handleEditProductSubmit = product => {
        const newProduct = { ...product, id: selectedProductId };
        updateProduct(newProduct)
            .then(res => res.json())
            .then(json => {
                if (json.status === "success")
                    setProducts(prev => {
                        const idx = products.findIndex(p => p.id === selectedProductId);
                        prev[idx] = newProduct;
                        return prev;
                    });
                setSelectedProductId(null);
                setShowEditProduct(false);
                return true;
            })
            .catch(() => false);
    }

    const handleEditProductClose = () => {
        setSelectedProductId(null);
        setShowEditProduct(false);
    }

    return <div className="ks-admin-products">
        <EditProductPopup
            product={selectedProduct}
            show={showEditProduct}
            onClose={handleEditProductClose}
            onSubmit={handleEditProductSubmit}
        />
        <Button
            variant="primary"
            className="mb-3"
            onClick={() => setShowAddProduct(prev => !prev)}
        >
            Dodaj produkt
        </Button>
        <AddProductWidget
            open={showAddProduct}
            onSubmit={product => {
                createProduct(product)
                    .then(res => res.json())
                    .then(json => {
                        if (json.status === "success")
                            setProducts(prev => [{ ...product, id: json.response.productId }, ...prev]);
                        return true;
                    })
                    .catch(() => false);
            }}
        />
        <Table
            header={[
                "Miniaturka",
                "Nazwa",
                "Opis",
                "Zdjęcia",
                "Cena",
                "Usuń"
            ]}
            onClick={handleProductClick}
        >
            {products.map(product => ({
                id: product.id,
                elements: [
                    product.thumbnail ?
                        <img src={product.thumbnail} alt="Miniaturka" className="ks-product-thumbnail" /> :
                        "Brak",
                    product.name,
                    `${product.description.substring(0, 20)} ...`,
                    product.images.length,
                    `${(product.price / 100).toFixed(2)} zł`,
                    <DeleteButton setProducts={setProducts}>
                        {product}
                    </DeleteButton>
                ]
            }))}
        </Table>
    </div>
};

export default Products;