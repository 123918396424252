import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { createDiscount, deleteDiscount, getDiscounts } from '../../dataProviders/discountsProvider';

const DEFAULT_DISCOUNT = {
    id: null,
    name: '',
    value_type: 'amount',
    value: 0,
    type: 'code',
    delivery_ids: [],
    product_ids: [],
    min_quantity: 0,
    min_amount: 0,
    code: ''
}
const TYPE_VALUES = {
    delivery: 'delivery_ids',
    product: 'product_ids',
    quantity: 'min_quantity',
    amount: 'min_amount',
    code: 'code'
}

function Discount({ discount, onCreate, onDelete }) {
    const [newDiscount, setNewDiscount] = useState(DEFAULT_DISCOUNT)

    const typeFieldName = TYPE_VALUES[newDiscount.type];
    const typeField = newDiscount[typeFieldName];
    const isDisabled = newDiscount.id !== null;
    const valueTypeChecked = newDiscount.value_type === 'percentage';
    const valueTypeSign = newDiscount.value_type === 'amount' ? 'gr' : '%';
    const value = newDiscount.value_type === 'amount' ? newDiscount.value : newDiscount.value;

    useEffect(() => {
        setNewDiscount(prev => ({ ...prev, ...(discount || {}) }));
    }, [discount]);

    const getValueTypeLabel = checked => {
        return checked ? 'percentage' : 'amount';
    }

    const handleFormChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        setNewDiscount(prev => ({ ...prev, [name]: value }));
    }

    const handleValueTypeChange = e => {
        const valueType = getValueTypeLabel(e.target.checked);
        setNewDiscount(prev => ({ ...prev, value_type: valueType }));
    }

    const handleDeleteOnClick = () => {
        deleteDiscount(newDiscount.id)
            .then(res => res.json())
            .then(json => json.status === 'success' && onDelete(newDiscount.id))
            .catch(console.log);
    };

    const handleCreateOnClick = () => {
        createDiscount(newDiscount)
            .then(res => res.json())
            .then(json => onCreate({ ...newDiscount, id: json.discountId }) && setNewDiscount(DEFAULT_DISCOUNT))
            .catch(console.log);
    };

    return <Form>
        <Row className="p-3 m-0 border-bottom">
            <Col xs={12} md={3} className='my-2 m-md-0'>
                <Form.Control
                    type='text'
                    name='name'
                    value={newDiscount.name}
                    onChange={handleFormChange}
                    disabled={isDisabled}
                />
            </Col>
            <Col xs={4} md={1} className='d-flex my-2 m-md-0'>
                gr
                <Form.Check
                    type='switch'
                    name='value_type'
                    checked={valueTypeChecked}
                    onChange={handleValueTypeChange}
                    className='ms-2'
                    disabled={isDisabled}
                />
                %
            </Col>
            <Col xs={8} md={2} className='my-2 m-md-0'>
                <InputGroup>
                    <InputGroup.Text className='w-25'>{valueTypeSign}</InputGroup.Text>
                    <Form.Control
                        type='number'
                        name='value'
                        value={value}
                        onChange={handleFormChange}
                        disabled={isDisabled}
                    />
                </InputGroup>
            </Col>
            <Col xs={12} md={2} className='my-2 m-md-0'>
                <Form.Select name='type' onChange={handleFormChange} value={newDiscount.type} disabled>
                    <option value='delivery'>dostawa</option>
                    <option value='product'>produkt</option>
                    <option value='quantity'>min prod.</option>
                    <option value='amount'>min cena</option>
                    <option value='code'>kod</option>
                </Form.Select>
            </Col>
            <Col xs={12} md={2} className='my-2 m-md-0'>
                <Form.Control
                    type='text'
                    name='code'
                    value={typeField}
                    onChange={handleFormChange}
                    disabled={isDisabled}
                />
            </Col>
            <Col xs={12} md={1} className='d-flex justify-center align-center my-2 m-md-0'>
                {
                    newDiscount.id === null ?
                        <Button
                            variant='success'
                            size='sm'
                            className='d-block m-auto'
                            onClick={handleCreateOnClick}
                        >
                            <i className="fas fa-save"></i>
                        </Button>
                        :
                        <Button
                            variant='danger'
                            size='sm'
                            className='d-block m-auto'
                            onClick={handleDeleteOnClick}
                        >
                            <i className="fas fa-trash fa-lg" />
                        </Button>
                }
            </Col>
        </Row>
    </Form >
}

function Discounts() {
    const [discounts, setDiscounts] = useState([]);

    useEffect(() => {
        getDiscounts()
            .then(res => res.json())
            .then(json => setDiscounts(json.response ?? []))
            .catch(console.log);
    }, []);

    const handleCreate = newDiscount => {
        setDiscounts(prev => ([...prev, newDiscount]));
        return true;
    }

    const handleDelete = id => {
        setDiscounts(prev => prev.filter(d => d.id !== id));
        return true;
    }

    return (
        <div className='border-top border-start border-end'>
            {discounts.map(d =>
                <Discount
                    key={d.id}
                    discount={d}
                    onDelete={handleDelete}
                />)}
            <Discount onCreate={handleCreate} />
        </div>
    )
}

export default Discounts;