import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { menuUrl } from "../urls";

const FormSubmitted = () => {
    return <div>
        <h3 className="text-center text-white pb-5">
            Dziękujemy za przesłanie wiadomości. <br />
            Wkrótce na nią odpowiemy.
        </h3>
        <Link to={menuUrl} className="text-decoration-none">
            <Button variant="danger" className="d-block mx-auto">
                Przejdź do menu
            </Button>
        </Link>
    </div>
}

export default FormSubmitted;