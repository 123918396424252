import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { confirmOrder, notifyOrder } from "../dataProviders/emailProvider";
import "./style.css";

const Summary = ({ clearCart }) => {
    let { id } = useParams();

    useEffect(() => {
        clearCart();
        window.gtag('event',
            'conversion',
            {
                'send_to': 'AW-991386239/AqKLCLSZv4UYEP-03dgD',
                'transaction_id': id
            });
        confirmOrder(id);
        notifyOrder(id);
    }, [clearCart, id]);

    return <div className="ks-summary">
        <h3>Dziękujemy za zakupy!</h3>
    </div>
}

export default Summary;